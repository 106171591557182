<template>
  <client-only v-if="props.type !== 'packs'">
    <div class="slider">
      <swiper class="products" :slides-per-view="4" :space-between="50" :breakpoints="breakpoints">
        <template v-for="product in products" :key="product.id">
          <swiper-slide v-if="product.type === props.type && props.type !== 'packs'">
            <TheProduct :product="product"></TheProduct>
          </swiper-slide>
        </template>
      </swiper>
    </div>

  </client-only>
  <client-only v-else>
    <div class="wrapper">
      <div class="products">
        <template v-for="product in products" :key="product.id">
          <template v-if="product.type === props.type">
            <div class="slider_wrapper">
              <TheProduct :height="height" :product="product"></TheProduct>

            </div>
          </template>

        </template>
      </div>
    </div>

  </client-only>
</template>
  
<script setup>
const height = ref(0)
onMounted(() => {
  nextTick(() => {
    const element = document.querySelector('.swiper-slide');
    if (element) {
      height.value = element.offsetHeight;
    }

  });
})
onUpdated(() => {
  nextTick(() => {
    const elements = document.querySelectorAll('.pack');
    elements.forEach(element => {
      // element.style.height = height
      console.log(element.style);
    });
  });


})
const props = defineProps({
  type: String
})
const products = [
  // {
  //   id: "free_coin",
  //   title: "Бесплатная монета",
  //   image: "coins_1",
  //   type: "coins",
  //   quantity: "1",
  //   isAdd: true,
  //   isIconNeeded: true,
  //   price: "$" + "10.99",
  // },
  {
    id: "coins.10",
    title: "Монеты",
    cong: 'монет',
    image: "coins_s",
    type: "coins",
    quantity: "10",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "1.99",
  },
  {
    id: "coins.30",
    title: "Монеты",
    cong: 'монет',
    image: "coins_m",
    type: "coins",
    quantity: "30",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "3.99",
  },
  {
    id: "coins.100",
    title: "Монеты",
    cong: 'монет',
    image: "coins_l",
    type: "coins",
    quantity: "100",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "7.99",
  },
  {
    id: "coins.200",
    title: "Монеты",
    cong: 'монет',
    image: "coins_xl",
    type: "coins",
    quantity: "200",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "11.99",
  },
  {
    id: "hints.1h",
    title: "Подсказки",
    cong: "Подсказки",
    image: "hour_hints",
    type: "coins",
    quantity: "НА 1 ЧАС",
    isAdd: false,
    isIconNeeded: false,
    price: "$" + "2.99",
  },
  {
    id: "hints.1g",
    title: "Подсказки",
    cong: "Подсказки",
    image: "game_hints",
    type: "coins",
    quantity: "НА 1 ИГРУ",
    isAdd: false,
    isIconNeeded: false,
    price: "$" + "6.99",
  },
  {
    id: "skips_hints",
    title: "Подсказки + Пропуски",
    image: "hints_skips",
    type: "coins",
    quantity: "НА 1 ИГРУ",
    isAdd: false,
    isIconNeeded: false,
    price: "$" + "6.99",
  },
  {
    id: "gems.5",
    title: "Рубины",
    cong: "рубинов",
    image: "gems_free",
    type: "gems",
    quantity: "5",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "3.99",
  },
  {
    id: "gems.10",
    title: "Рубины",
    cong: "рубинов",
    image: "gems_s",
    type: "gems",
    quantity: "10",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "5.99",
  },
  {
    id: "gems.15",
    title: "Рубины",
    cong: "рубинов",
    image: "gems_m",
    type: "gems",
    quantity: "15",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "6.99",
  },
  {
    id: "gems.20",
    title: "Рубины",
    cong: "рубинов",
    image: "gems_l",
    type: "gems",
    quantity: "20",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "7.99",
  },
  {
    id: "gems.30",
    title: "Рубины",
    cong: "рубинов",
    image: "gems_xl",
    type: "gems",
    quantity: "30",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "8.99",
  },
  {
    id: "skips.1h",
    title: "Пропуски",
    cong: "Пропуски",
    image: "hour_skips",
    type: "gems",
    quantity: "НА 1 ЧАС",
    isAdd: false,
    isIconNeeded: false,
    price: "$" + "2.99",
  },
  {
    id: "skips.1g",
    title: "Пропуски",
    cong: "Пропуски",
    image: "game_skips",
    type: "gems",
    quantity: "НА 1 ИГРУ",
    isAdd: false,
    isIconNeeded: false,
    price: "$" + "6.99",
  },
  {
    id: "skips_hints",
    title: "Подсказки + Пропуски",
    cong: "Подсказки и Пропуски",
    image: "hints_skips",
    type: "gems",
    quantity: "НА 1 ИГРУ",
    isAdd: false,
    isIconNeeded: false,
    price: "$" + "6.99",
  },
  {
    id: "bundle3games",
    title: "Лучшее для 3 игр",
    cong: 'монет',
    cong2: 'рубинов',
    image: "packs_l",
    type: "packs",
    quantity: "150",
    quantity2: "25",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "12.99",
    old_price: "$" + "15.98"
  },
  {
    id: "bundle5games",
    title: "Лучшее для 5 игр",
    cong: 'монет',
    cong2: 'рубинов',
    image: "packs_xl",
    type: "packs",
    quantity: "250",
    quantity2: "40",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "18.99",
    old_price: "$" + "25.98"
  },
  {
    id: "bundle10games",
    title: "Лучшее для 10 игр",
    cong: 'монет',
    cong2: 'рубинов',
    image: "packs_xxl",
    type: "packs",
    quantity: "500",
    quantity2: "80",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "27.99",
    old_price: "$" + "44.97"
  },
];
let breakpoints = {
  0: {
    slidesPerView: 3,
  },
  652: {
    slidesPerView: 4,
  },
  654: {
    slidesPerView: 3,
  },
  668: {
    slidesPerView: 4,
  },
  768: {
    slidesPerView: 4,
  },
  1024: {
    slidesPerView: 3,
  },
};
</script>
  
<style lang="scss" scoped>
.slider {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.products {
  padding: 0 60px;

}

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: flex-end;

  .products {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 0 60px;
  }


  .slider_wrapper {
    position: relative;
  }
}
</style>