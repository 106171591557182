<template>
  <TheSlider :type="currentTab"></TheSlider>
  <TheTabs @set-tab="setCurrentTab"></TheTabs>
  <div v-if="isWindowShowed" class="popup">
    <div class="window">
      <div class="wrapper">
        <h3>Поздравляем!</h3>
        <img :src="'/images/png/' + window_object.image + '.png'" alt="" />
        <div class="text">
          <p>Вы получили</p>
          <p>
            {{ window_object.quantity }} {{ window_object.cong }} <span v-if="window_object.type === 'packs'">И {{ window_object.quantity2 }} {{ window_object.cong2 }}</span>
          </p>
        </div>

        <button @click="isWindowShowed = !isWindowShowed">OK</button>
      </div>
    </div>
    <div class="dark"></div>
  </div>
</template>
<style lang="scss">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .window {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-size: 600px 400px;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url("/images/png/bg.png");
    img {
      max-width: 180px;
      width: 100%;
    }
    .wrapper {
      h3 {
        font-weight: 500;
        text-shadow: 0 0 7px black;
      }
      .text {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          text-transform: uppercase;
          text-align: center;
          line-height: 100%;
        }
      }

      // max-width: 250px;
      display: grid;
      justify-content: center;
      grid-template-columns: 1fr;
      grid-template-rows: 30px 130px 50px 40px;
      gap: 10px;
      padding: 30px;
      width: 500px;
      height: 325px;
      // background-color: lightblue;
      box-sizing: border-box;
      * {
        margin: 0 auto;
      }
    }

    button {
      box-sizing: border-box;

      bottom: 0;
      left: 0;
      right: 0;
      max-width: 90%;
      height: auto;
      margin: auto;
      aspect-ratio: 298/85;
      background-image: url("/images/png/button_new.png");
      background-size: cover;

      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;

      p {
        text-align: center;

        color: white;
        font-weight: 500;
        font-size: clamp(1.4vw, 3vw, 2vw);
      }
    }
  }
  .dark {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    background-color: #000;
    opacity: 0.5;
  }
}
</style>
<script setup>
import { useBalanceStore } from "@/stores/balance";

const route = useRoute();
const query = ref(route.query);

const currentTab = ref("coins");

const balanceStore = useBalanceStore();
const baseUrl =
  "https://us-central1-dominiclub-android.cloudfunctions.net/getUserInfo_v2";

let response_coins = ref({});
let response_gems = ref({});
let window_object = ref({
    id: "bundle10games",
    title: "Лучшее для 10 игр",
    cong: 'монет',
    cong2: 'рубинов',
    image: "packs_xxl",
    type: "packs",
    quantity: "500",
    quantity2: "80",
    isAdd: false,
    isIconNeeded: true,
    price: "$" + "27.99",
    old_price: "$" + "44.97"
  },);
let isWindowShowed = ref(false);

function setBalance(coins, gems) {
  if (document !== undefined) {
    balanceStore.balance = {
      coins: coins,
      gems: gems,
    };
  }
}

if (query.value?.uid) {
  (async function getUserInfo() {
    await $axios()
      .get("/firebase", {
        params: {
          uid: query.value?.uid,
        },
      })
      .then((res) => {
        response_coins.value = res?.data?.resources?.COINS;
        response_gems.value = res?.data?.resources?.CRYSTALS;
      })
      .then((res) => setBalance(cCoins.value, cGems.value));
  })();
  // console.log(cCoins);
  // setBalance(cCoins.value, cGems.value)
}

const cCoins = computed(() => {
  let COINS_SUM = 0;
  for (var coin in response_coins.value) {
    if (response_coins.value.hasOwnProperty(coin)) {
      COINS_SUM += response_coins.value[coin];
    }
  }
  return COINS_SUM;
});

const cGems = computed(() => {
  let GEMS_SUM = 0;
  for (var gem in response_gems.value) {
    if (response_gems.value.hasOwnProperty(gem)) {
      GEMS_SUM += response_gems.value[gem];
    }
  }
  return GEMS_SUM;
});

function setCurrentTab(tabName) {
  currentTab.value = tabName;
}

function showAfterPurchase(strObj) {
  const object = JSON.parse(strObj);
  window_object.value = object;
  isWindowShowed.value = true;
}

onMounted(() => {
  if (window !== undefined) {
    window.setBalance = setBalance;
    window.showAfterPurchase = showAfterPurchase;
  }
});
</script>
