<script setup>
function logInfo() {
    Android.getSku(JSON.stringify(props.product))
}
const props = defineProps({
    product: Object,
    height: Number
});
function imgUrl() {
    return new URL(`../assets/${this.icon}.svg`, import.meta.url).href;
}
</script>

<template>
    <div @click="logInfo" :class="{ pack: props.product.type === 'packs' }"
        :style="{ height: props.product.type === 'packs' ? props.height + 'px' : 'auto' }" class="product">
        <div class="wrapper">
            <h2 :class="{ last: props.product.id === 'skips_hints' }">
                {{ props.product?.title }}
            </h2>
            <img class="icon" :src="'/images/png/' + props.product.image + '.png'" alt="" />
            <div v-if="props.product.type !== 'packs'" class="info">
                <img v-if="props.product.isIconNeeded" :src="'/images/png/' + props.product?.type + '.png'" alt="" />
                <p>{{ props.product?.quantity }}</p>
            </div>
            <div v-else class="info packs">
                <div class="info">
                    <img v-if="props.product.isIconNeeded" :src="'/images/png/coins.png'" alt="" />
                    <p>{{ props.product?.quantity }}</p>
                </div>
                <div class="info">
                    <img v-if="props.product.isIconNeeded" :src="'/images/png/gems.png'" alt="" />
                    <p>{{ props.product?.quantity2 }}</p>
                </div>
            </div>
            <div class="button_wrapper">
                <div v-if="!props.product?.isAdd" class="button">
                    <p>{{ props.product?.price }}</p>
                </div>
                <div v-else class="button blue">
                    <p>Смотреть</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "assets/styles/variables";

.pack {
    width: 100%;
    height: auto;
    max-height: 62vh !important;
}

.product {
    aspect-ratio: 222/355;
    max-height: calc(100vh - clamp(40px, 100vh, 10vh));
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('/images/png/get_object_back.png');
    background-size: cover;
    margin-bottom: 10%;

    .wrapper {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 14% 59% 20%;
        justify-content: center;
        align-items: center;

        .icon {
            width: 100%;
            height: auto;
        }

        h2.last {
            font-size: clamp(5px, 2.5vh, 24px);
        }

        h2 {
            color: rgb(187, 241, 255);
            font-size: clamp(5px, 3vh, 24px);
            line-height: 70%;
            text-align: center;
            text-transform: uppercase;
        }

        .info.packs {
            gap: 40px
        }

        .info {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1vh;

            img {
                width: clamp(20px, 5vh, 30px);
            }

            p {
                color: wheat;
                font-weight: 500;
                text-align: center;
                font-size: clamp(5px, 3vh, 24px);
            }
        }

        .button_wrapper {
            width: 50%;
            padding: 0 20px;

            .blue {
                background-image: url('/images/png/button_blue_new.png') !important;
            }

            .button {
                box-sizing: border-box;

                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                max-width: 90%;
                height: auto;
                margin: auto;
                aspect-ratio: 298/85;
                background-image: url('/images/png/button_new.png');
                background-size: cover;

                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    text-align: center;

                    color: white;
                    font-weight: 500;
                    font-size: clamp(1.4vw, 3vw, 2vw);
                }
            }
        }
    }
}

@media screen and (max-width: 670px) {
    .product {
        .wrapper {
            h2 {
                font-size: 10px;
            }

            .info {
                img {
                    width: 15px;
                    height: 16px;
                }
            }

            .button_wrapper {
                .blue {
                    background-image: url('/images/png/button_blue_new.png') !important;
                }

                .button {
                    box-sizing: border-box;

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    max-width: 90%;
                    height: auto;
                    margin: auto;
                    aspect-ratio: 298/85;
                    background-image: url('/images/png/button_new.png');
                    background-size: cover;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        text-align: center;

                        color: white;
                        font-weight: 500;
                        font-size: clamp(1.4vw, 3vw, 2vw);
                    }
                }
            }
        }
    }
}

@media screen and (min-aspect-ratio: 1.33) and (max-aspect-ratio: 1.8) and (min-device-width: 768px) and (max-device-width: 1400px) and (-webkit-min-device-pixel-ratio: 2) {
    .product {
        .wrapper {
            .button_wrapper {
                .blue {
                    background-image: url('/images/png/button_blue_new.png') !important;
                }

                .button {
                    box-sizing: border-box;

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    max-width: 90%;
                    height: auto;
                    margin: auto;
                    aspect-ratio: 298/85;
                    background-image: url('/images/png/button_new.png');
                    background-size: cover;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        text-align: center;

                        color: white;
                        font-weight: 500;
                        font-size: clamp(1.4vw, 3vw, 3vw);
                    }
                }
            }
        }
    }
}
</style>
