import { ref } from 'vue'
import axios from 'axios'



export function $axios() {
    // const config = useRuntimeConfig()

    const instance = axios.create({});

    return instance
}
