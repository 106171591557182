<script setup>
let activeTab = ref({
  id: 0,
  name: 'coins'
})
const tabs = [
  {
    id: 0,
    name: "coins",
  },
  {
    id: 1,
    name: "gems",
  },
  {
    id: 2,
    name: "packs",
  },
];
const emit = defineEmits(["setTab"]);
function setTab(tab) {
  emit('setTab', tab.name)
  activeTab.value = tab
}
</script>

<template>
  <div class="tabs">
    <div @click="setTab(tab)" v-for="tab in tabs" :key="tab.id"
      :class="{ tab: true, [tab.name]: true, active: activeTab.id === tab.id }"></div>
  </div>
</template>

<style lang="scss">
.tabs {
  padding-top: 5px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;

  .tab.active {
    &::before {
      content: '';
      z-index: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url('/images/png/active.png');
      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .coins {
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url('/images/png/tab_coins.png');
      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
      transition: .2s all;
    }

  }

  .coins.active {
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url('/images/png/tab_coins_checked.png');
      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
      transition: .2s all;

    }

    // background-image: url(/images/png/tab_coins_checked.png);
  }

  .gems {
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url('/images/png/tab_gems.png');

      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
      transition: .2s all;
    }
  }

  .gems.active {
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url('/images/png/tab_gems_checked.png');

      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
      transition: .2s all;
      transition: .2s all;
    }
  }
  .packs {
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url('/images/png/tab_packs.png');

      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
      transition: .2s all;
    }
  }

  .packs.active {
    &::after {
      content: '';
      z-index: 1;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url('/images/png/tab_packs_checked.png');

      background-size: cover;
      display: block;
      width: 100%;
      height: 100%;
      transition: .2s all;
      transition: .2s all;
    }
  }

  .tab {
    aspect-ratio: 150/133;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: clamp(20%, 40%, 30%);
    position: relative;
    z-index: 1;

    p {
      font-weight: 500;
      color: white;
      font-size: clamp(1em, 100vw, 2vw);
      margin-top: 1.3vw;
      margin-left: 3.5vw;
    }
  }
}
</style>